import styled from "styled-components";

const ImageComponent = (props)=> {
    return <StyledImage
                $marginTop={props.marginTop}
                src={props.src}
                alt={props.alt}
                />
}

const StyledImage = styled.img`
    margin-top: ${props => props.$marginTop || "20px"};
    width: 100%;
    height: auto;
`

export default ImageComponent