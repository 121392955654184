import styled from "styled-components";

const ContainerComponent = (props)=> {
    return <ComponentContainer bgcolor={props.bgcolor}>
        {props.children}
    </ComponentContainer>
}

const ComponentContainer = styled.div`
    display: flex;
    flex-direction : column;
    margin: 10px;
    justify-content: center;
    width: auto;
    background-color: ${props => props.bgcolor || ''};
    z-index: ${props => props.zIndex};
`

export default ContainerComponent