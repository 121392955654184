import { useEffect, useState } from "react"

import FormComponent from "../components/FormComponent"
import ContainerComponent from "../components/ContainerComponent"
import TextComponent from "../components/TextComponent"
import QuestionButtonComponent from "../components/QuestionButtonComponent"
import { Text } from "../common/constant"
import { useRoutingFunction } from "../hooks/userouting"
import { useStore } from "../state/usestore"

import styled from "styled-components"

import ReactGA from 'react-ga';
ReactGA.initialize('G-6ETDEL94C7');

const CenteredContainer = styled.div`
    display: flex;
    justify-content: center;
    /* align-items: center; */
    height: 100vh;
    background-color: #FAFAFA;
    max-width: 600px;
    width: auto;
    margin: auto;
`;

const QuestionIE = (props)=> {
    const moveNextQuestions = useRoutingFunction(`${props.number}`,"")
    const state = useStore(state => state)
    const setAnswerState = (value) => state[`set_IE_${props.number}`](value)
    
    const [randomresult, setRandomresult] = useState(0);
    
    useEffect(() => {
        setRandomresult(Math.round(Math.random()))
        console.log("random result:", randomresult)
    },[randomresult,props.number]);

    
    useEffect(() => {
        const buttons = document.querySelectorAll('button');
    
        buttons.forEach((button) => {
            button.addEventListener('mouseover', () => {
            button.blur();
            });
        });
    
        return () => {
            buttons.forEach((button) => {
                button.removeEventListener('mouseover', () => {
                button.blur();
                });
            });
        };
    }, [props.number]);

    useEffect(()=>{

        // google analytics
        const pagePath = window.location.pathname + window.location.search + window.location.hash;
        ReactGA.pageview(pagePath);
    },[]);
    
    return <CenteredContainer>
        <QStyledForm>
        <HeaderContainerComponent>
        <TextComponent 
            msg = {`${props.number}/12`}
            fontsize = {"1.2em"}>
            </TextComponent>
        </HeaderContainerComponent>
        <QContainerComponent>
            <TextComponent 
            msg = {Text[`question_type_IE_${props.number}`]}
            fontsize = {"1.4em"}
            txtwrap={"wrap"}>
            </TextComponent>
        </QContainerComponent>
        
            {randomresult?
            <> 
            <QuestionButtonComponent 
            msg = {Text[`q_type_IE_${props.number}_answer_I`]}
            onClick = {() => {
                moveNextQuestions()
                setAnswerState("I")
                console.log(state[`questionIE_${props.number}_answer`])
            }}>
            </QuestionButtonComponent> 
            <QuestionButtonComponent 
            msg = {Text[`q_type_IE_${props.number}_answer_E`]}
            onClick = {() => {
                moveNextQuestions()
                setAnswerState("E")
                console.log(state[`questionIE_${props.number}_answer`])
            }}>
            </QuestionButtonComponent>
            </>
            : <>
            <QuestionButtonComponent 
            msg = {Text[`q_type_IE_${props.number}_answer_E`]}
            onClick = {() => {
                moveNextQuestions()
                setAnswerState("E")
                console.log(state[`questionIE_${props.number}_answer`])
            }}>
            </QuestionButtonComponent>
            <QuestionButtonComponent 
            msg = {Text[`q_type_IE_${props.number}_answer_I`]}
            onClick = {() => {
                moveNextQuestions()
                setAnswerState("I")
                console.log(state[`questionIE_${props.number}_answer`])
            }}>
            </QuestionButtonComponent>
            </>}
    </QStyledForm>
    </CenteredContainer>
}

const QStyledForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* height: 800px; */
    max-width: 600px;
    margin-top : 200px;
    width: 100%;
`

const QContainerComponent = styled.div`
    display: flex;
    flex-direction : column;
    margin: 10px 10px 10px 20px;
    justify-content: center;
    width: auto;
`

const HeaderContainerComponent = styled.div`
    display: flex;
    width : 20%;
    margin-left : 20px;
    align-items : flex-start;
`;

export default QuestionIE