import styled from "styled-components";

const TitleComponent = (props)=> {
    return <TitleTag 
            $txtcolor={props.$txtcolor}
            $marginTop={props.marginTop}
            $marginBottom={props.marginBottom}>
            {props.msg}
        </TitleTag>
}

const TitleTag = styled.p`
    margin-top: ${props => props.$marginTop || "20px"};
    margin-bottom: ${props => props.$marginBottom || "0px"};
    font-size: 2.0em;
    font-family: P-B;
    color : ${props => props.$txtcolor || "white"};
    white-space: nowrap;
    z-index : 10;
`

export default TitleComponent