import { create } from "zustand";
import { persist } from "zustand/middleware";
import { routingIE, routingTF } from "../common/constant";

export const useStore = create(
  persist((set) => {
    const initialStateIE = Object.fromEntries(
      routingIE.map((store) => [`questionIE_${store}_answer`, null])
    );

    const initialStateTF = Object.fromEntries(
      routingTF.map((store) => [`questionTF_${store}_answer`, null])
    );

    return {
      ...initialStateIE,
      ...initialStateTF,
      ...Object.fromEntries(
        routingIE.map((store) => [
          `set_IE_${store}`,
          (value) => set(() => ({ [`questionIE_${store}_answer`]: value })),
        ])
      ),
      ...Object.fromEntries(
        routingTF.map((store) => [
          `set_TF_${store}`,
          (value) => set(() => ({ [`questionTF_${store}_answer`]: value })),
        ])
      ),
    };
  }, 
  {
    name: 'session-state',
    getStorage: () => sessionStorage 
  }
));

