import styled from "styled-components";

const ShareButtonComponent = (props)=> {
    return <StyledButton 
            onClick={props.onClick}
            $txtcolor={props.$txtcolor}
            bgcolor={props.bgcolor}
            radius={props.radius}
            widthratio={props.widthratio}
            buttonheight={props.buttonheight}
            fontsize={props.fontsize}
            fontFamily={props.fontFamily}
            >
            {props.imgSrc && <img src={props.imgSrc} alt={props.imgAlt || ''} />}
            {props.msg}
        </StyledButton>
}

const StyledButton = styled.button`
    display: flex;
    font-size: ${props => props.fontsize || "1.2em"};
    font-family: ${props => props.fontFamily || "P-M"};
    border-radius: ${props => props.radius || "20px"};
    border-style: none;
    background-color: ${props => props.bgcolor || "black"};
    color: ${props => props.$txtcolor || "white"};
    width: ${props => props.widthratio || "87%"};
    height: ${props => props.buttonheight || "2.0em"};
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &:hover {
        background-color: lightgray;
    color: black;
    }
`

export default ShareButtonComponent