import { useNavigate } from 'react-router-dom';
import { routingIE, routingTF, type_question_num } from "../common/constant"
import { useResult } from '../util/result';
// import { useStore } from "../state/usestore"

// @param question_number_string The number of question
// @param page_parameter where the page is
export function useRoutingFunction(question_number_string, page_parameter) {
    const resultstring = useResult()
    const navigate = useNavigate();
    // const state = useStore(state => state)
    let urlstring = "/"
    const combined_routing = [...routingIE, ...routingTF]
    const combined_map = {}
    for (let i = 0; i < combined_routing.length-1; i++) {
        combined_map[combined_routing[i]] = combined_routing[i+1]
    }

    if (page_parameter === "S") {
        // console.log(page_parameter, "move to question")
        urlstring += "test/01"
        // console.log("this is url string : ", urlstring)
    }  else if (page_parameter === "E") {
        // console.log(page_parameter, "restart")
        // console.log("this is url string : ", urlstring)
    } else if (question_number_string === `${type_question_num * 2}`) {
        urlstring += "result/" + resultstring
    } else {
        // console.log(combined_map)
        console.log(`value : ${combined_map[question_number_string]}`)
        urlstring += `test/${combined_map[question_number_string]}`
    }
    
    return () => {
        // console.log("01 value" + state[`questionIE_01_answer`])
        // console.log("02 value" + state[`questionIE_02_answer`])
        // console.log("urlstring is : ", urlstring)
        navigate(urlstring)
    }
}