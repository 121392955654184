export const Text = {
    // Home_text
    "home_title": "<호작도> 캐릭터 TEST",
    "home_subtitle": "까치와 호랑이, 나와 닮은 민화 캐릭터는?",
    "home_enter": "나의 캐릭터 알아보기🐯",
    "home_maji_insta": "https://www.instagram.com/maji.kr?igsh=MXV4OThzOWQzNGY4dA==",
    "hozakdo_url": "https://hozakdo-test.co.kr",
    // "hozakdo_url": "useonj.github.io",
    
    // IE_TEST
    "question_type_IE_01": "친구들과 주말 내내 놀고 집에 가는 중이다.",
    "q_type_IE_01_answer_I": "얼른 집 가서 충전해야지.",
    "q_type_IE_01_answer_E": "더 놀고 싶은데… 아쉽다!",
    
    "question_type_IE_02": "옛날에 친했지만 연락이 뜸해진 친구가 멀리서 걸어오고 있다.",
    "q_type_IE_02_answer_I": "속으로만 반가워하고, 무덤덤하게 지나친다.",
    "q_type_IE_02_answer_E": "‘이게 얼마만이야!’ 먼저 웃으며 인사를 한다.",
    
    "question_type_IE_03": "갑자기 생긴 휴식 시간, 무엇을 하면 좋을까?",
    "q_type_IE_03_answer_I": "넷플릭스가 내 친구. 집에서 하루 종일 쉼을 만끽한다.",
    "q_type_IE_03_answer_E": "당연히 친구들 만나야지! 바로 약속을 잡는다.",
    
    "question_type_IE_04": "친한 친구가 나와의 약속에 모르는 친구를 데리고 온다고 한다.",
    "q_type_IE_04_answer_I": "아… 다음에 만나자… ^^",
    "q_type_IE_04_answer_E": "니 친구가 내 친구지! 같이 놀자~",
    
    "question_type_IE_05": "나갈 준비를 다 했는데, 당일에 약속이 갑자기 취소됐다.",
    "q_type_IE_05_answer_I": "아싸 신난다~ 집에서 쉬어야지!",
    "q_type_IE_05_answer_E": "아… 다른 친구 불러봐야지 ㅠㅠ",

    "question_type_IE_06": "사람 많은 모임 장소에서 나는?",
    "q_type_IE_06_answer_I": "‘아 진짜? 정말?’ 주로 리액션 담당",
    "q_type_IE_06_answer_E": "‘내가 어제 말이야~’ 주로 말하는 역할",
    
    // TF_TEST
    "question_type_TF_01": "친구가 미용실에서 머리를 망쳐서 속상해 한다. ",
    "q_type_TF_01_answer_T": "어디야? 보상해준대?",
    "q_type_TF_01_answer_F": "이 머리도 나름… 귀여운 것 같아..! (토닥토닥)",
    
    "question_type_TF_02": "‘상사한테 자꾸 혼나서 힘들어’ 친구의 말에 내 반응은?",
    "q_type_TF_02_answer_T": "왜? 상사가 뭐라고 하는데?",
    "q_type_TF_02_answer_F": "아휴 속상하겠다… 힘내…",

    
    "question_type_TF_03": "친구에게 새로운 애인이 생겼다.",
    "q_type_TF_03_answer_T": "오, 어떤 사람이야?",
    "q_type_TF_03_answer_F": "우와 축하해!! 잘돼서 좋다~",
    
    "question_type_TF_04": "나 이번에 회사 그만뒀어!",
    "q_type_TF_04_answer_T": "오 그럼 이직하는 거야?",
    "q_type_TF_04_answer_F": "헐.. 회사에서 무슨 일 있었어?",
    
    "question_type_TF_05": "나 자동차 사고 났어ㅠㅠ",
    "q_type_TF_05_answer_T": "보험은 들었어?",
    "q_type_TF_05_answer_F": "많이 안 다쳤어? 괜찮아??",
    
    "question_type_TF_06": "길을 가다가 다친 고양이를 발견했다.",
    "q_type_TF_06_answer_T": "아니.. 어쩌다가 다쳤지..?",
    "q_type_TF_06_answer_F": "헉 불쌍해 ㅠㅠ",
    
    "result_text_cool_tiger_header" : "논리적인 판단+강한 추진력을 지닌 리더",
    "result_text_cool_tiger_subheader" : "‘쿨호랑이’ 타입",
    "result_text_cool_tiger_1" : `논리와 추진력을 모두 갖춘 천상 리더인 당신은 ‘쿨호랑이’. 토론과 논쟁을 즐길 줄 알고, 문제를 해결할 때 기쁨을 느껴요. 결단을 빨리 내리고, 강한 추진력을 가지고 있어서 ‘행동대장’ 리더라고 할 수 있습니다. 활동적이며 에너지가 늘 넘치고, 자기 주장이 확실하다는 평가를 많이 받아요.`,    
    "result_text_cool_tiger_2" : `그러나 자기만의 신념과 원칙이 확고하기 때문에, 그걸 벗어나는 사람과는 친해지기 어려워요. 특히 자신감이 떨어지는 사람들을 보면 답답해 합니다. 일을 빠르게 추진하려고 하다 보니 ‘거칠다’는 인상을 주거나, 이 때문에 자칫 오만한 ‘고집불통’인 것처럼 보일 수 있어 주의가 필요해요.`,
    
    "result_text_warm_tiger_header" : "뜨거운 열정을 지닌 분위기 메이커",
    "result_text_warm_tiger_subheader" : "‘웜호랑이’ 타입",
    "result_text_warm_tiger_1" : `이 구역 사교계의 왕은 바로 당신 ‘웜호랑이’! 사람들과 어울리는 일을 통해 에너지가 채워져요. 늘 ‘인싸’라는 말을 듣고, 어떤 자리에서든 분위기 메이커 역할을 하게 됩니다. 눈치가 빠르기 때문에 모임 자리에서 누가 기분이 나쁘고, 좋은지 빨리 알아채서, 분위기를 부드럽게 잘 전환시켜요. 갈등이 생기면 ‘중재자’ 역할을 하는 편이죠.`, 
    "result_text_warm_tiger_2" : `감정 표현을 잘하고, 사람들과의 감정적인 소통을 통해 기쁨을 느껴요. 그러나 자칫 눈치를 과하게 볼 때가 있어서 ‘이 사람이 혹시 나 때문에 마음이 상했나?’ 혼자 마음 속으로 괴로워할 때가 있어요. 특히 갈등 상황에 놓였을 때 해결하고 싶어하는 마음이 큰데, 이런 상황이 지속되면 과한 스트레스를 받을 수 있어 주의가 필요합니다.`,
    
    "result_text_cool_magpie_header" : "예리한 눈을 가진 신중한 관찰자",
    "result_text_cool_magpie_subheader" : "‘쿨까치’ 타입",
    "result_text_cool_magpie_1" : `‘쿨까치’ 타입의 당신은 조용하고 혼자 있기를 좋아해요. 그러나 속으로는 늘 심사숙고하느라 바쁘죠. 사건의 원인과 결과, 사물의 원리를 파고들기를 좋아합니다. 다른 사람들과 어울리기보다는 혼자만의 시간을 가지며 나 자신에 대해 탐구하는 데에 관심이 있어요. 다른 유형과는 비교하기 힘들 정도로 ‘깊게’ 덕질하는 분야가 있는 경우가 많습니다. 문제가 발생하면 우선 해결하려고 들기보다는 한 발짝 물러서서 관찰하는 편이라 ‘신중하다’는 인상을 줍니다.`, 
    "result_text_cool_magpie_2" : `자신에게 집중하는 유형이기 때문에 다른 사람들 눈에는 자칫 차가운 사람으로 비춰질 수 있어 주의가 필요합니다. 사회적인 모임에 나가, 여러 관계를 만드는 걸 선호하지 않거든요. 특히 내가 관심 있는 분야가 아닌, 단순히 친해지기 위한 사적인 대화는 ‘쓸 데 없다’고 느낄 때가 많아 참여하지 않으려고 해요.`,
    
    "result_text_warm_magpie_header" : "따뜻하고 세심한 감수성을 숨기고 있는",
    "result_text_warm_magpie_subheader" : "‘웜까치’ 타입",
    "result_text_warm_magpie_1" : `표현은 잘 못해도 마음 속에 따뜻한 배려심을 품고 있는 당신은 ‘웜까치’. 속으로는 그 누구보다 감수성이 풍부하지만, 다른 사람들에게 표현하는 건 조금 부끄러워요. 혼자만의 시간 속에서 공상과 상상에 빠지는 경우가 많아요. ‘감정’을 중시하기 때문에, 내 말이나 행동에 상대방이 어떤 ‘감정’을 느끼는지도 예민하게 봅니다. 다른 사람과 내 의견이 조금 달라도 유연하게 받아들이는 포용력이 있어요.`, 
    "result_text_warm_magpie_2" : `그러나 내가 정말 중요하다고 여기는 몇 가지 ‘신념’에 대해서는 고집을 절대 꺾지 않습니다. 사람들 사이에 갈등이 생기면 해결하기 보다는, 일단 회피하려는 성향을 가지고 있어요. 생각이 지나치게 깊고, 많아서 그걸 다른 사람에게 전달하는 데 어려움을 겪기도 해요. 자칫하면 주도적이지 못하고, 지나치게 의존적이라는 인상을 줄 수 있습니다.`,
    
    "result_text_pine_header" : "언제든 캐릭터 변신 가능!",
    "result_text_pine_subheader" : "중립적인 ‘소나무’ 타입",
    "result_text_pine_1" : `분위기와 환경에 따라 언제든지 변신 가능한 당신은 중립적인 ‘소나무’. 외부 활동을 귀찮아 하는 편이지만, 막상 나가면 엄청 잘 노는 스타일이에요. 주변 사람들이 어떤 사람들이냐에 따라서 이런 모습으로도, 저런 모습으로도 바뀔 수 있는 변신의 귀재입니다. 그래서 주변에서도 나에 대한 평가가 ‘극과 극’일 수 있어요.`, 
    "result_text_pine_2" : `혹은 소나무 유형은 아직까지 스스로가 어떤 사람인지 헷갈리거나, 가치관이 확립되지 않은 상태일 수도 있습니다. 자기 자신이 어떤 사람인지 깊이 생각해보는 시간을 가져봐도 좋아요.`,

    "result_tumblbug"   : "🧧 나만의 행운부적 구매하기 🧧",
    "tumblbug_url"      : "https://tumblbug.com/hozakdo",
    "result_urlcopy"    : "URL 복사하기",
    "result_kakao"      : "카카오톡 공유하기",
    "result_X"          : "X(트위터) 공유하기",
    "result_instagram"  : "인스타그램 공유하기",
    "result_restart"    : "테스트 다시하기",

    "download" : "결과 저장하기",

    "title_square"        : "http://k.kakaocdn.net/dn/b5zGQV/bl4EQrPl413/wGIio3oD0z5KHAuMJU5rf1/kakaolink40_original.jpg",
    "cool_magpie_square"  : "http://k.kakaocdn.net/dn/c2rjxC/bl4FOSRnlOr/zcMVw64GTKd0N0fcL6kKyK/kakaolink40_original.jpg",
    "warm_magpie_square"  : "http://k.kakaocdn.net/dn/b425ME/bl4EQqJHcW8/IWSIAnKLgzkTmzGkPB7rPK/kakaolink40_original.jpg",
    "cool_tiger_square"   : "http://k.kakaocdn.net/dn/G0HHN/bl4Fism5QH5/GS6CMZxUQbQ7dv47Dd0qNK/kakaolink40_original.jpg",
    "warm_tiger_square"   : "http://k.kakaocdn.net/dn/dgAcLM/bl4EMTL9oEZ/ZgeMoqLwQkDOXSA2BTLx21/kakaolink40_original.jpg",
    "pine_square"         : "http://k.kakaocdn.net/dn/bHW02o/bl4FqTRWbtO/dbbQQULtXsODPmazcIyuu0/kakaolink40_original.jpg",

    "title_imageUrl"        : "http://k.kakaocdn.net/dn/bdWrtp/bl4EZBwP6S3/AjzcpEacbAfDhbN5OqTMYk/kakaolink40_original.jpg",
    "cool_magpie_imageUrl"  : "http://k.kakaocdn.net/dn/cIOHuY/bl4E9wjkwNM/hiBZIAim2S1OjGW37Z2rck/kakaolink40_original.jpg",
    "warm_magpie_imageUrl"  : "http://k.kakaocdn.net/dn/0WfFY/bl4FJ5QVRUv/ECxlGkmfTVAnZ3ZMCkJyv0/kakaolink40_original.jpg",
    "cool_tiger_imageUrl"   : "http://k.kakaocdn.net/dn/EEwKI/bl4FuWAPfpX/IjkM4ZCAQw9X5DRaK8yaqk/kakaolink40_original.jpg",
    "warm_tiger_imageUrl"   : "http://k.kakaocdn.net/dn/CVeJ4/bl4FJ4EubYG/FtsOHJCxLBic85BA5Xw1T0/kakaolink40_original.jpg",
    "pine_imageUrl"         : "http://k.kakaocdn.net/dn/v9tzm/bl4EHxiAyzo/86YLwjWwcnJKizTIJj23J1/kakaolink40_original.jpg",
}

export const type_matching = {
    "IT" : "cool_magpie",
    "IF" : "warm_magpie",
    "ET" : "cool_tiger",
    "EF" : "warm_tiger",
    "pine" : "pine",
}

export const routingIE = ["01", "02", "03", "04", "05", "06"]
export const routingTF = ["07", "08", "09", "10", "11", "12"]
export const results = ["cool_magpie", "warm_magpie", "cool_tiger", "warm_tiger", "pine"]
export const type_question_num = 6

export const HashTags = {
    "cool_magpie": ["#혼자가좋아", "#신중고민형🤔", "#좁고깊은인간관계"],
    "warm_magpie": ["#따뜻한츤데레", "#새벽감성폭발", "#예민도치🦔"],
    "cool_tiger": ["#나를따르라", "#불같은추진력🔥", "#고집쟁이"],
    "warm_tiger": ["#이구역인싸", "#눈치빠름👀", "#사람좋아인간"],
    "pine": ["#변신의귀재😎", "#극과극", "#인싸면서아싸"]
}
