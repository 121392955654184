import { useStore } from "../state/usestore";
import { routingIE, routingTF, type_question_num , type_matching } from "../common/constant";

export function useResult() {
    let result_type = "";
    const state = useStore(state => state);

    const IE_array =  routingIE.map( s => state[`questionIE_${s}_answer`])
    const TF_array =  routingTF.map( s => state[`questionTF_${s}_answer`])

    const getElNum = (arr, el) => arr.reduce((ac, v) => ac + (v === el), 0);

    const I_num = getElNum(IE_array, "I")
    const T_num = getElNum(TF_array, "T")

    console.log("I_num: " + I_num, "T_num: " + T_num)

    if (I_num === 3 && T_num === 3) {
        
        result_type = "pine";

    } else if (I_num === 3) {

        if (getElNum(IE_array.slice(0,type_question_num-2), "I") >= 3) {

            if (T_num > 3) {
                result_type = "IT";
            } else {
                result_type = "IF";
            }

        } else {

            if (T_num > 3) {
                result_type = "ET";
            } else {
                result_type = "EF";
            }

        }

    } else if (T_num === 3){

        if (getElNum(TF_array.slice(0,type_question_num-2), "T") >= 3) {

            if (I_num > 3) {
                result_type = "IT";
            } else {
                result_type = "ET";
            }

        } else {

            if (I_num > 3) {
                result_type = "IF";
            } else {
                result_type = "EF";
            }

        }
    } else if (I_num > 3) {

        if (T_num > 3) {
            result_type = "IT";
        } else {
            result_type = "IF";
        }

    } else {

        if (T_num > 3) {
            result_type = "ET";
        } else {
            result_type = "EF";
        }
        
    }

    result_type = type_matching[result_type]

    console.log(state, result_type)

    return result_type
}