import styled from "styled-components";

const FormComponent = (props)=> {
    const handleSubmit = (e) => {
        e.preventDefault();
        if (props.onSubmit) {
            props.onSubmit(e);
        }
    }
    return <StyledForm onSubmit={handleSubmit} bgcolor={props.bgcolor}>
        {props.children}
    </StyledForm>
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: calc(100vh - 119px);
    max-width: 600px;
    background-color: ${props => props.bgcolor || ''};
    width: 100%;
`

export default FormComponent