import FormComponent from "../components/FormComponent"
import ContainerComponent from "../components/ContainerComponent"
import TextComponent from "../components/TextComponent"
import TitleComponent from "../components/TitleComponent"
import ButtonComponent from "../components/ButtonComponent"
import ShareButtonComponent from "../components/ShareButtonComponent"
import SubShareButtonComponent from "../components/SubShareButtonComponent"
import ImageComponent from "../components/ImageComponent"
import {Text, HashTags } from "../common/constant"
import { Base64 } from "../common/base64"

import { useRoutingFunction } from "../hooks/userouting"
// import { useResult } from "../util/result"

import { React, useEffect, useState } from "react"
import { PostPageStoriesPhotoMediaRequest } from "instagram-graph-api"
import styled, { keyframes } from 'styled-components'

import ReactGA from 'react-ga'
ReactGA.initialize('G-6ETDEL94C7');
const { Kakao } = window;

// 상태에 따라 다른 텍스트 노출하도록
const Result = (props)=> {

    const [isCopied, setIsCopied] = useState(false);
    const [isFading, setIsFading] = useState(false);

    useEffect(()=>{
        Kakao.cleanup();
        // js key
        Kakao.init(process.env.REACT_APP_kakao_js_key);

        // google analytics
        const pagePath = window.location.pathname + window.location.search + window.location.hash;
        ReactGA.pageview(pagePath);
    },[]);
    
    // const result = useResult();

    const hadleClickTumblbug = () => {
        if (window.gtag) {
            window.gtag('event', 'Click_Tumblbug', {
                'event_category': 'User',
                'event_label': 'Result Move to Tumblbug'
            });
        }
        ReactGA.event({
            category: 'User',
            action: 'Click_Tumblbug',
            label: 'result_move_to_tumblbug'
        });
        window.open(`${Text.tumblbug_url}`)}

    const handleClickKakao = () => {
        if (window.gtag) {
            window.gtag('event', 'Click_Kakao_Result', {
                'event_category': 'User',
                'event_label': 'Result Kakao Share'
            });
        }
        ReactGA.event({
            category: 'User',
            action: 'Click_Kakao_Result',
            label: 'result_kakao_share'
        });
        // console.log(`${process.env.REACT_APP_deployment_url}/result/${props.result}`)
        const urlstring = props.result + "_square"
        Kakao.Share.sendDefault({
            objectType: 'feed',
            content: {
                title: '<호작도> 캐릭터 TEST',
                description: `${Text.home_subtitle}`,
                imageUrl:
                `${Text[urlstring]}`,
                link: {
                    mobileWebUrl: `${process.env.REACT_APP_deployment_url}/#/result/${props.result}`,
                },
            },
            buttons: [
                {
                    title: '나도 테스트 하러가기',
                    link: {
                    mobileWebUrl: `${process.env.REACT_APP_deployment_url}/#/result/${props.result}`,
                    },
                },
                ],
        })
    }

    const handleClickX = () => {
        if (window.gtag) {
            window.gtag('event', 'Click_Twitter', {
                'event_category': 'User',
                'event_label': 'Result Twitter Share'
            });
        }
        ReactGA.event({
            category: 'User',
            action: 'Click_Twitter',
            label: 'result_twitter_share'
        });
        window.open(`https://www.twitter.com/intent/tweet?&url=${process.env.REACT_APP_deployment_url}/#/result/${props.result}`)}
    
    // const urlstring = result + "_share"

    const handleClickInstagram = () => {
        if (window.gtag) {
            window.gtag('event', 'Click_Instagram', {
                'event_category': 'User',
                'event_label': 'Result Instagram Share'
            });
        }
        ReactGA.event({
            category: 'User',
            action: 'Click_Instagram',
            label: 'result_instagram_share'
        });
        
        const instagramStoriesUrl = 'instagram-stories://share';
        
        // handleImageDownload(imageUrl, fileName)
        // source_application=${appID}
        window.open(`${instagramStoriesUrl}?&backgroundImage=${Text[`${props.result}_imageUrl`]}`);
    }

    const navigation = useRoutingFunction("","E")
    const handleClickRestart = () => {
        ReactGA.event({
            category: 'User',
            action: 'Click_Restart',
            label: 'result_restart'
        });
        navigation()
    }

    const handleImageDownload = (imageUrl, fileName) => {
        if (window.gtag) {
            window.gtag('event', 'Download_Result', {
                'event_category': 'Download',
                'event_label': 'Result Image Download'
            });
        }
        ReactGA.event({
            category: 'User',
            action: 'Download',
            label: 'result_image_download'
        });

        const a = document.createElement('a');
        a.href = imageUrl;
        a.download = fileName;
        a.click();
    }

    const handleCopyToClipboard = async () => {
        if (window.gtag) {
            window.gtag('event', 'Click_URL_Result', {
                'event_category': 'URL',
                'event_label': 'Result Copy to Clipboard'
            });
        }
        ReactGA.event({
            category: 'URL',
            action: 'Click_URL_Result',
            label: 'result_copy_to_clipboard'
        });

        try {
          // 현재 URL 복사 Text.hozakdo_url => `${process.env.REACT_APP_deployment_url}/#/result/${props.result}`
        await navigator.clipboard.writeText(`${process.env.REACT_APP_deployment_url}/#/result/${props.result}`);
        setIsCopied(true);
        setTimeout(() => setIsFading(true), 1500); // x초 후에 페이드 아웃 시작
        setTimeout(() => { setIsCopied(false); setIsFading(false); }, 3000); // x초 후에 메시지 완전히 숨기기
        console.log('URL이 복사되었습니다');
        } catch (err) {
        return alert('URL 복사에 실패하였습니다.', err);
        }
    };

    // const handleClickUpload = () => {
    //     const fileInput = document.getElementById('upload');
    //     const file = fileInput.files[0]; // 첫 번째 파일 가져오기

    // if (!file) {
    // console.log('No file selected');
    // return;
    // }

    // Kakao.Share.uploadImage({
    // file: fileInput.files,
    // })
    // .then(response => {
    // console.log('Uploaded image URL:', response.infos.original.url);
    // })
    // .catch(error => {
    // console.error('Error uploading image:', error);
    // });
    // }


    return <CenteredContainer>
        <DivComponent>
        <ImageContainer>
            <Image src={`./img/${props.result}.jpg`} alt={`배경에 소나무가 있는 ${props.result} 이미지`} />
        </ImageContainer>
        <ImageDownload 
        $txtcolor = {props.result === 'warm_magpie'? "black" : "white"}
        onClick={() => handleImageDownload(`./img/${props.result}_share.jpg`,`${props.result}`)}>
            <DownloadImage src={props.result === 'warm_magpie'? `./img/download_black.png` : `./img/download_white.png`}/>
            {Text.download}
        </ImageDownload>
        
        <CenteredContainerComponent>
            <TextComponent 
            msg = {Text[`result_text_${props.result}_header`]}
            fontsize = "1.2em"
            txtwrap = "wrap"
            >
            </TextComponent>
        </CenteredContainerComponent>
        
        <CenteredContainerSubComponent>
            <TitleComponent 
            msg = {Text[`result_text_${props.result}_subheader`]}
            $txtcolor = "black"
            txtwrap = "wrap"
            marginTop = "0px"
            marginBottom = "10px"
            >
            </TitleComponent>
        </CenteredContainerSubComponent>

        {/* 해쉬 태그 */}
        <HashTagContainer>
            {HashTags[`${props.result}`].map( (tag,i) => <HashTag key={i}>{tag}</HashTag>)}
        </HashTagContainer>

        {/* 본문 */}
        <CenteredContainerTextComponent>
            <TextComponent 
            msg = {Text[`result_text_${props.result}_1`]}
            fontsize = "1.2em"
            fontFamily = "P-R"
            txtwrap = "wrap"
            >
            </TextComponent>
            </CenteredContainerTextComponent>
            <CenteredContainerTextComponent>
            <TextComponent 
            msg = {Text[`result_text_${props.result}_2`]}
            fontsize = "1.2em"
            fontFamily = "P-R"
            txtwrap = "wrap"
            >
            </TextComponent>
        </CenteredContainerTextComponent>

        {/* 공유버튼 */}
        <CenteredContainerComponent>
            <ShareButtonComponent 
            msg = {Text.result_tumblbug}
            onClick={hadleClickTumblbug}
            widthratio = "75%"
            fontsize = "1.1em"
            >
            </ShareButtonComponent>
        </CenteredContainerComponent>

        <CenteredContainerComponent>
            <SubShareButtonComponent 
            msg = {Text.result_urlcopy}
            onClick={handleCopyToClipboard}
            widthratio = "75%"
            fontsize = "1.1em"
            >
            </SubShareButtonComponent>
        </CenteredContainerComponent>
        {/* url 복사 메세지 */}
        <CopyMessage show={isCopied} fading={isFading}>URL이 복사되었습니다</CopyMessage>

        <CenteredContainerComponent>
            <SubShareButtonComponent 
            msg = {Text.result_instagram}
            onClick={() => handleClickInstagram()}
            widthratio = "75%"
            fontsize = "1.1em"
            >
            </SubShareButtonComponent>
        </CenteredContainerComponent>
        
        <CenteredContainerComponent>
            <SubShareButtonComponent 
            msg = {Text.result_X}
            onClick={handleClickX}
            widthratio = "75%"
            fontsize = "1.1em"
            >
            </SubShareButtonComponent>
        </CenteredContainerComponent>
        
        <CenteredContainerComponent>
            <SubShareButtonComponent 
            msg = {Text.result_restart}
            onClick={handleClickRestart}
            widthratio = "75%"
            fontsize = "1.1em"
            >
            </SubShareButtonComponent>
        </CenteredContainerComponent>
        {/* 임시 버튼 */}
        {/* <CenteredContainerComponent>
            <input type="file" id="upload" />
            <button onClick={handleClickUpload}>Upload</button>
        </CenteredContainerComponent> */}
        {/* 임시 버튼 */}
            <CenteredContainerDummy />
        <KakaoContainerComponent>
            <ButtonComponent 
            msg = {Text.result_kakao}
            onClick={handleClickKakao}
            $txtcolor = "black"
            bgcolor = "rgb(255, 235, 0)"
            radius = "0px"
            widthratio = "100%"
            buttonheight = "100%"
            fontsize = "1.0em"
            fontFamily = "P-EBold"
            >
            <KakaoImage src = "./img/kakao_logo.png"/>
            </ButtonComponent>
        </KakaoContainerComponent>
    </DivComponent>
    </CenteredContainer>
}

const CenteredContainer = styled.div`
    display: flex;
    justify-content: center;
    max-width: 600px;
    width: auto;
    margin: auto;
    overflow: auto;
`;

const CenteredContainerComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    width: auto;
    margin: 30px 30px 10px 30px;
`;
const CenteredContainerSubComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    width: auto;
    margin: 0px 30px 10px 30px;
`;

const CenteredContainerTextComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    width: auto;
    margin: 10px 30px;
`;

const CenteredContainerDummy = styled.div`
    justify-content: center;
    align-items: center;
    max-width: 600px;
    width: auto;
    height: 10.0em;
`;

const ImageContainer = styled.div`
    width: 600px;
    height: 450px;
    max-width: 100%;
    overflow: hidden;
`;

const Image = styled.img`
    width: 100%;
    height: 100%; 
    object-fit: cover; 
    object-position: center 30%;
`;

const KakaoImage = styled.img`
    width: 38px;
`;

const DownloadImage = styled.img`
    width: 21px;
`;

const ImageDownload = styled.button`
    display: flex;
    
    position: absolute;
    top: 405px;
    right: 10px;
    padding: 10px 10px;
    background : transparent;
    border-style : none;
    color: ${props => props.$txtcolor || "rgb(255, 255, 255)"};
    font-size: 1.0em;
    font-family: P-Bold;
    white-space: nowrap;

    @media (min-width:601px) {
        right: unset; 
        left: calc(50% + 160px); 
    };
`

const HashTagContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    max-width: 90%;
    margin: 0px 30px 0px;

    @media (max-width:381px) {
        margin: 0px 70px;
        flex-wrap: wrap;
    };
    @media (min-width: 440px) and (max-width: 600px) {
        margin: 0px calc(45px + 50 * (100vw - 440px) / 160);
    }
    @media (min-width: 600px) {
        margin: 0px 120px;
    }
`

const HashTag = styled.div`
    display: flex;
    align-items: center;
    height: 33px;
    font-family: P-Bold;
    font-size: 0.9em;
    border-radius: 100px;
    background: rgb(241, 243, 248);
    padding: 0px 12px;
    margin: 6px 3px;
    white-space: nowrap;
`
const DivComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    max-width: 600px;
    background-color: ${props => props.bgcolor || ''};
    width: 100%;
    overflow: auto;
`

const KakaoContainerComponent = styled.div`
    width: 100%;
    position: fixed;
    z-index: 11;
    bottom: -3px;
    left: 50%; // 화면 가운데에 위치하도록 변경
    transform: translateX(-50%); // 컨테이너의 가운데를 기준으로 이동하도록 변경
    font-size: 15px;
    height: 50px;
    max-width:600px;
`

const CopyMessage = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #303030;
    color: #fff;
    z-index: 100;
    padding: 15px 20px;
    border-radius: 5px;
    font-family: "P-M";
    opacity: ${props => props.fading ? 0 : 1};
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    transition: opacity 1.5s linear;
`

export default Result