import styled, { keyframes } from 'styled-components'

import FormComponent from "../components/FormComponent"
import ContainerComponent from "../components/ContainerComponent"
import TextComponent from "../components/TextComponent"
import ButtonComponent from "../components/ButtonComponent"
import ImageComponent from "../components/ImageComponent"
import { Text } from "../common/constant"
import { useRoutingFunction } from "../hooks/userouting"
import TitleComponent from "../components/TitleComponent"

import React, { useState, useEffect } from 'react';

import ReactGA from 'react-ga';
ReactGA.initialize('G-6ETDEL94C7');

const { Kakao } = window;

const Home = ()=> {

    const [isCopied, setIsCopied] = useState(false);
    const [isFading, setIsFading] = useState(false);

    useEffect(()=>{
        Kakao.cleanup();
        // js key
        Kakao.init(process.env.REACT_APP_kakao_js_key);

        // google analytics
        const pagePath = window.location.pathname + window.location.search + window.location.hash;
        ReactGA.pageview(pagePath);
    },[]);

    const handleClickMaji = () => {
        if (window.gtag) {
            console.log("gtag maji logo")
            window.gtag('event', 'Click_MAJI', {
                'event_category': 'User',
                'event_label': 'home_move_to_maji_instagram'
            });
        }
        ReactGA.event({
            category: 'User',
            action: 'Click_MAJI',
            label: 'home_move_to_maji_instagram'
        });
        
        window.open(`${Text.home_maji_insta}`)
    }

    const handleClickKakao = () => {
        if (window.gtag) {
            window.gtag('event', 'Click_Kakao', {
                'event_category': 'User',
                'event_label': 'Home Kakao Share'
            });
        }

        ReactGA.event({
            category: 'User',
            action: 'Click_Kakao',
            label: 'Home Kakao Share'
        });

        Kakao.Share.sendDefault({
            objectType: 'feed',
            content: {
                title: '<호작도> 캐릭터 TEST',
                description: `${Text.home_subtitle}`,
                imageUrl:
                `${Text.title_square}`,
                link: {
                    mobileWebUrl: process.env.REACT_APP_deployment_url,
                },
            },
            buttons: [
                {
                    title: '나도 테스트 하러가기',
                    link: {
                    mobileWebUrl: process.env.REACT_APP_deployment_url,
                    },
                },
                ],
        })
    }

    const handleCopyToClipboard = async () => {
        
        if (window.gtag) {
            window.gtag('event', 'Click_URL', {
                'event_category': 'User',
                'event_label': 'Home Copy to Clipboard'
            });
        }

        ReactGA.event({
            category: 'User',
            action: 'Click_URL',
            label: 'Home Copy to Clipboard'
        });

        try {
          // 현재 URL 복사
        await navigator.clipboard.writeText(Text.hozakdo_url);
        setIsCopied(true);
        setTimeout(() => setIsFading(true), 1500); // 2초 후에 페이드 아웃 시작
        setTimeout(() => { setIsCopied(false); setIsFading(false); }, 3000); // 4초 후에 메시지 완전히 숨기기
        console.log('URL이 복사되었습니다');
        } catch (err) {
        return alert('URL 복사에 실패하였습니다.', err);
        }
    };

    const moveToQuestions = useRoutingFunction("","S")
    const [marginTop, setMarginTop] = useState(0);
    const [marginTopR, setMarginTopR] = useState(0);
    
    useEffect(() => {
        const handleResize = () => {
            const viewportHeight = window.innerHeight;
            const newMarginTop = viewportHeight > 821 ? (viewportHeight - 821) / 2 : 0;
            setMarginTop(newMarginTop);
            let newMarginTopR = viewportHeight <= 821 ? (821 - viewportHeight) : 0;
            newMarginTopR = Math.min(newMarginTopR, 115)
            setMarginTopR(newMarginTopR);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // 초기 마진 설정

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    console.log("margin Top : " + marginTop)
    console.log("margin TopR : " + marginTopR)

    return <CenteredContainer>
        <HeaderContainer>
        <Header>
            <LogoComponent 
            src="./img/magi_logo.png" 
            alt="magi브랜드의 알파벳 이니셜 로고"
            data-ga-category="MAJI" data-ga-action="Click_MAJI" data-ga-label="MAJI Logo"
            onClick={handleClickMaji}/>
        </Header>
        </HeaderContainer>
        <FormComponent>
            <MainTitleContainer 
            $marginTop={marginTop} 
            $marginTopR={marginTopR}
            >
            <TitleComponent 
            msg = {Text.home_title}>
            </TitleComponent>
            </MainTitleContainer>
            <SubTitleContainer
            $marginTop={marginTop} 
            $marginTopR={marginTopR}
            >
            <TextComponent 
            msg = {Text.home_subtitle}
            fontsize = "1.2em" 
            txtcolor = "white">
            </TextComponent>
            </SubTitleContainer>
            <TitleImage
                $marginTop={marginTop} 
                $marginTopR={marginTopR}
                src = "./img/new_title_img.jpg"
            />

            {/* 그라데이션 */}
            <GradientContainer />

            {/* 공유버튼 2가지 */}
            <KakaoHomeContainerComponent>
            <ButtonComponent
            data-ga-category="User" data-ga-action="Click_Kakao" data-ga-label="Home Kakao Share"
            onClick={handleClickKakao}
            $txtcolor = "black"
            bgcolor = "transparent"
            hoverbgcolor = "transparent"
            radius = "0px"
            widthratio = "100%"
            buttonheight = "100%"
            fontsize = "1.0em"
            fontFamily = "P-EBold"
            imgSrc = "./img/kakao_logo.png"
            />
            </KakaoHomeContainerComponent>

            <URLCopyContainerComponent>
            <ButtonComponent
            data-ga-category="URL" data-ga-action="Click_URL" data-ga-label="Home URL Share"
            onClick={handleCopyToClipboard}
            $txtcolor = "black"
            bgcolor = "lightgray"
            hoverbgcolor = "transparent"
            radius = "10px"
            widthratio = "100%"
            buttonheight = "100%"
            fontsize = "1.0em"
            fontFamily = "P-EBold"
            msg = "URL"
                />
            </URLCopyContainerComponent>

            {/* url 복사 메세지 */}
            <CopyMessage show={isCopied} fading={isFading}>URL이 복사되었습니다</CopyMessage>

            <ButtonContainer>
            <ButtonComponent 
            msg = {Text.home_enter}
            onClick={moveToQuestions}
            >
            </ButtonComponent>
            </ButtonContainer>
        
    </FormComponent>
    </CenteredContainer>
}

const CenteredContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a00f00;
    max-width: 600px;
    height: calc(100%-119px);
    width: auto;
    margin: auto;
`;

const HeaderContainer = styled.div`
    position: fixed;
    top: 1%;
    left: calc(50% - min(300px, 50vw));
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    z-index : 100;
`;

const Header = styled.header`
    top: 0;
    width: 100%; 
`;

const LogoComponent = styled.img`
    height:26px;
    width: auto;
    margin-left: 10px;
    margin-top: 20px;
`

const MainTitleContainer = styled.div`
    display: flex;
    position: fixed;
    top: 170px;
    @media (max-height: 821px) {
        top: ${props => `calc(190px - ${props.$marginTopR}px)`};
    }
    @media (min-height: 822px) {
        top: ${props => `calc(190px + ${props.$marginTop}px)`};
    }

    left: 50%;
    transform: translateX(-50%);

    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 10;
`

const SubTitleContainer = styled.div`
    display: flex;
    position: fixed;
    top: 190px;
    @media (max-height: 821px) {
        top: ${props => `calc(250px - ${props.$marginTopR}px)`};
    }
    @media (min-height: 822px) {
        top: ${props => `calc(250px + ${props.$marginTop}px)`};
    }

    left: 50%;
    transform: translateX(-50%);

    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 10;
`

const TitleImage = styled.img`
    margin-top: ${props => props.$marginTop || "20px"};

    @media (max-height: 821px) {
        margin-top: ${props => `calc(200px - ${props.$marginTopR}px)`};
    }
    @media (min-height: 822px) {
        margin-top: ${props => `calc(200px + ${props.$marginTop}px)`};
    }
    width: 100%;
    height: auto;
`

const GradientContainer = styled.div`
    max-width: 600px;
    height: 100px;
    position: fixed;
    bottom: 118px;
    width: 100%;
    background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0));
`

const KakaoHomeContainerComponent = styled.div`
    width: 40px;
    position: fixed;
    z-index: 11;
    bottom: 140px;
    right: calc(50% - 40vw); // 화면 가운데에 위치하도록 변경
    @media (min-width: 601px) {
        right: calc(50% - 240px);
    }
    transform: translateX(-50%); // 컨테이너의 가운데를 기준으로 이동하도록 변경
    height: 40px;
    max-width:600px;
`

const URLCopyContainerComponent = styled.div`
    width: 37.5px;
    position: fixed;
    z-index: 11;
    bottom: 140px;
    right: calc(50% - 50vw); // 화면 가운데에 위치하도록 변경
    @media (min-width: 601px) {
        right: calc(50% - 290px);
    }
    transform: translateX(-50%); // 컨테이너의 가운데를 기준으로 이동하도록 변경
    height: 37.5px;
    max-width:600px;
`

const ButtonContainer = styled.div`
    /* margin-top: 50px; */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 100%;
    max-width: 600px;
    bottom: 0px;
    height: 119px;
    background: rgb(255, 255, 255);
`

const CopyMessage = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #303030;
    color: #fff;
    z-index: 100;
    padding: 15px 20px;
    border-radius: 5px;
    font-family: "P-M";
    opacity: ${props => props.fading ? 0 : 1};
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    transition: opacity 1.5s linear;
`

export default Home