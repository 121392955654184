import styled from "styled-components";

const TextComponent = (props)=> {
    return <TextParagraph 
    $txtcolor={props.txtcolor}
    $fontsize={props.fontsize}
    fontFamily={props.fontFamily}
    txtwrap={props.txtwrap}>
            {props.msg}
        </TextParagraph>
}

const TextParagraph = styled.p`
    font-size: ${props => props.$fontsize || '1.4em'};
    font-family: ${props => props.fontFamily || "P-M"};
    color: ${props => props.$txtcolor || 'black'};
    white-space: ${props => props.txtwrap || 'nowrap'};
    width: auto;
    z-index: 10;
`

export default TextComponent