import Home from "./pages/Home"
import QuestionIE from "./pages/QuestionIE"
import QuestionTF from "./pages/QuestionTF"
import Result from "./pages/Result"

import { routingIE, routingTF, results } from "./common/constant"
import { Route, Routes } from "react-router-dom";

import GlobalStyle from "./GlobalStyle";

function App() {
  return (
    <>
    <GlobalStyle />
    <Routes>
      <Route path="/" element={<Home />} />
      {routingIE.map((num) => 
        <Route path={`/test/${num}`} key={num} element={<QuestionIE number={num}/>} />
      )}
      {routingTF.map((num) => 
        <Route path={`/test/${num}`} key={num} element={<QuestionTF number={num}/>} />
      )}
      {results.map((result) => 
        <Route path={`/result/${result}`} key={result} element={<Result result={result}/>} />
      )}
    </Routes>
    </>
  );
}

export default App;
