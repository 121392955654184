import styled from "styled-components";

const QuestionButtonComponent = (props)=> {
    return <StyledButtonQ onClick={props.onClick}>
            {props.msg}
        </StyledButtonQ>
}

const StyledButtonQ = styled.button`
    display: flex;
    font-family: P-M;
    border-style: none;
    width: 87.2%;
    text-align: left;
    margin: 0px auto 16px;
    background: rgb(241, 243, 248);
    padding: 20px 24px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 1.38;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: lightgray;
    color: black;
    }
`

export default QuestionButtonComponent