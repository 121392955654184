import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

@font-face {
font-family: P-B;
src: url('./fonts/Pretendard-Black.otf');
}

@font-face {
font-family: P-Bold;
src: url('./fonts/Pretendard-Bold.otf');
}

@font-face {
font-family: P-EBold;
src: url('./fonts/Pretendard-ExtraBold.otf');
}

@font-face {
font-family: P-SemiBold;
src: url('./fonts/Pretendard-SemiBold.otf');
}

@font-face {
font-family: P-ELight;
src: url('./fonts/Pretendard-ExtraLight.otf');
}

@font-face {
font-family: P-M;
src: url('./fonts/Pretendard-Medium.otf');
}

@font-face {
font-family: P-R;
src: url('./fonts/Pretendard-Regular.otf');
}

@font-face {
font-family: P-L;
src: url('./fonts/Pretendard-Light.otf');
}

@font-face {
font-family: P-T;
src: url('./fonts/Pretendard-Thin.otf');
}

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

button:focus {
    outline: none;
}

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
`;

export default GlobalStyle;